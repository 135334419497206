import React from "react";
import Web3 from "web3";
import { Web3ReactProvider } from "@web3-react/core";
import { Route, Routes } from "react-router-dom";

import { StoreContextProvider } from "./contexts/store";
import Register from "./pages/Register";
import Home from "./pages/Home";
import Success from "./pages/Success";
import NotFound from "./pages/NotFound";
import DefaultLayout from "./layouts/DefaultLayout";

import "./App.sass";

export default React.memo(() => {
  const library = (provider: any): Web3 => {
    console.log({
      "provider.isQubic": provider.isQubic,
    });
    return new Web3(provider);
  };

  return (
    <StoreContextProvider>
      <Web3ReactProvider getLibrary={library}>
        <Routes>
          <Route path="/" element={<DefaultLayout />}>
            <Route path="*" element={<NotFound />} />
            <Route path="/" element={<Home />} />
            <Route path="register" element={<Register />} />
            <Route path="success" element={<Success />} />
          </Route>
        </Routes>
      </Web3ReactProvider>
    </StoreContextProvider>
  );
});
