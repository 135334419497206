import { Outlet, Link } from "react-router-dom";

import headerSrc from "../assets/header.png";
import footerSrc from "../assets/footer.png";

function DefaultLayout() {
  return (
    <div className="layout">
      <header className="header">
        <Link to="/">
          <img src={headerSrc} alt="header" />
        </Link>
      </header>
      <main className="content">
        <Outlet />
      </main>
      <footer className="footer">
        <img src={footerSrc} alt="footer" />
      </footer>
    </div>
  );
}

export default DefaultLayout;
