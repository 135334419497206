import { Link } from "react-router-dom";

function NotFound() {
  return (
    <div className="grid grid-cols-1 gap-8 text-center">
      <div className="text-hint text-3xl text-center">Page Not Found</div>
      <Link to="/">
        <button className="btn">回到首頁</button>
      </Link>
    </div>
  );
}

export default NotFound;
