import { useEffect, useCallback } from "react";
import Web3 from "web3";
import { QubicConnector } from "@qubic-js/react";
import { useWeb3React } from "@web3-react/core";
import qs from "query-string";
import ReactGA from "react-ga4";
import { useNavigate } from "react-router-dom";

import { useEmail, useSetEmail } from "../contexts/store";
import qubicSrc from "../assets/qubic.png";

type registerProps = {
  client: string;
  tag: string;
  address: string;
  remark?: string;
};

const qubicConnector = new QubicConnector({
  apiKey: "eef6979f-38b3-4d14-a1ce-071549886814",
  apiSecret: "ZneKPssapGRXzfoKNgwqVzozhIIeVwno",
  chainId: 1,
  infuraProjectId: "",
  // optional, default: false, when value is true, the popup will hide automatically
  autoHideWelcome: false,
  // optional, default: false, when value is true, the show iframe instead of new window, credit card payment will failed with this option value true
  enableIframe: false,
});

const CLIENT = process.env.REACT_APP_AIRDROP_CLIENT || "Qubic";
const DEST_URL =
  "https://script.google.com/macros/s/AKfycbwIMwIv8cYQrYpovGI4wqcDrCFIDFPFyPA-MsSe_g0rYJF164vu8yjyxNR-JRUgy515_Q/exec";

const convertToString = (val: string | string[] | null, defaultVal: string) => {
  if (Array.isArray(val)) {
    return val.join(",");
  }
  if (typeof val === "string" && val.length > 0) {
    return val;
  }
  return defaultVal;
};

const useHandleSignIn = () => {
  const email = useEmail();
  const setEmail = useSetEmail();
  const navigate = useNavigate();

  const search = qs.parse(window.location.search);
  const tag = convertToString(search.tag, "");

  const context = useWeb3React<Web3>();
  const { account, activate } = context;
  const handleSignIn = useCallback(async () => {
    await activate(qubicConnector, (e: Error): void => {
      console.error(e);
    });
  }, [activate]);

  useEffect(() => {
    if (!account || !email) return;

    let isUnmounted = false;

    const submitRegister = async (query: registerProps) => {
      const response = await fetch(qs.stringifyUrl({ url: DEST_URL, query }));
      const { status } = await response.json();
      if (status === "success" && !isUnmounted) {
        setEmail("");
        setTimeout(() => {
          navigate("/success");
        });
      }
    };

    submitRegister({
      client: CLIENT,
      tag,
      address: account,
      remark: email,
    });

    return () => {
      isUnmounted = true;
    };
  }, [account, tag, email, navigate, setEmail]);

  return { handleSignIn, account };
};

const useEmailProtect = () => {
  const email = useEmail();
  const navigate = useNavigate();
  useEffect(() => {
    if (!email) {
      navigate("/");
    }
  }, [email, navigate]);
  return { email };
};

export default function Register() {
  useEffect(() => {
    ReactGA.initialize([
      {
        trackingId: "G-VXX6SSFVTX",
      },
    ]);
    ReactGA.send("pageview");
  }, []);

  useEmailProtect();
  const { handleSignIn } = useHandleSignIn();

  return (
    <div className="text-center grid grid-cols-1 gap-6">
      <div className="headline">NFT 元宇宙體驗啟程！</div>
      <div className="text-left">
        在您領取 NFT 前，我們將引導您完成 Qubic 虛擬貨幣錢包設定，確保 NFT
        能妥善保存；過程將無任何安全疑慮
      </div>
      <div className="text-left">5 秒鐘，帶您輕鬆前進元宇宙！</div>
      <img src={qubicSrc} alt="qubic" />
      <div className="pt-8">
        <button className="btn" onClick={handleSignIn}>
          註冊錢包/登入錢包
        </button>
      </div>
    </div>
  );
}
