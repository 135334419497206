import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useSetEmail } from "../contexts/store";
import nftImageSrc from "../assets/taishin-nft.jpg"
import nftVideoSrc from "../assets/taishin-nft.mp4"

function Home() {
  const setEmail = useSetEmail();
  const navigate = useNavigate();
  const [keyInEmail, setKeyInEmail] = useState("");
  const [videoMuted, setVideoMuted] = useState(true);
  const handleKeyInEmailChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setKeyInEmail(e.target.value);
    },
    []
  );

  const handleVideoClick = useCallback(() => {
    setVideoMuted(false);
  }, [])

  const handleSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      setEmail(keyInEmail);
      setTimeout(() => {
        navigate("/register");
      });
    },
    [keyInEmail, navigate, setEmail]
  );

  return (
    <div className="text-center grid grid-cols-1 gap-6">
      <div className="headline">貴賓領獎通知</div>
      <div>恭喜您得到「唬你糊塗 傻傻幸福 NFT ! 」</div>

      <video
        className="mx-auto"
        poster={nftImageSrc}
        autoPlay
        loop
        muted={videoMuted} // Need muted at first can autoplay.
        onClick={handleVideoClick} // User click to enable video sound.
      >
        <source src={nftVideoSrc} type="video/mp4" />
      </video>

      <div className="text-left">
        請於 24 小時內點選「登記領取」完成登記，逾期無效！請依照指示申請活動用
        NFT 錢包並留下您的資料，您的 NFT 將會在 7 月 31 日傳送至您填寫的錢包
      </div>

      <form onSubmit={handleSubmit} className="grid grid-cols-1 gap-6">
        <div className="text-left">
          登記領取 NFT 前，請輸入您在 3TET
          商城之前下單填寫的Email，本活動限本人領取
        </div>
        <div>
          <input
            className="input"
            placeholder="請輸入您的 Email"
            type="email"
            required
            value={keyInEmail}
            onChange={handleKeyInEmailChange}
          />
        </div>
        <div>此 Email 將用於驗證您的領獎資格，NFT 無法轉贈</div>
        <div className="py-6">
          <button className="btn">登記領取</button>
        </div>
      </form>

      <div className="text-left text-hint">
        貼心提醒：NFT 限量 80 份，請於收取到領獎訊息 24
        小時內完成登記，以確保您的領獎權益。
      </div>
    </div>
  );
}

export default Home;
