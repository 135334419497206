import checkSrc from "../assets/check.png";

import appStoreSrc from "../assets/dl_apple_store.svg";
import googlePlaySrc from "../assets/dl_google_play.svg";
import webappSrc from "../assets/web_app.svg";

function Success() {
  return (
    <div className="grid grid-cols-1 gap-6">
      <div className="text-center headline">登記成功</div>
      <img className="mx-auto py-4" src={checkSrc} alt="success" />
      <div>
        您的 NFT 將會在 7 月 31 日傳送至您申請的錢包，錢包註冊 /
        登入請使用剛所選擇的 FB / Google / Apple
        帳號，並請備份或妥善保管您的密碼
      </div>
      <div className="py-8 grid grid-cols-1 gap-4">
        <div>您可下載 Qubic App 或使用 Web 版查看您的 NFT！</div>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mx-auto">
          <a
            href="https://apps.apple.com/tw/app/id1563987988"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img width={100} height={32} src={appStoreSrc} alt="apple store" />
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=app.qubic.wallet"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              width={100}
              height={32}
              src={googlePlaySrc}
              alt="google play"
            />
          </a>
          <a
            href="https://wallet.qubic.app"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img width={100} height={32} src={webappSrc} alt="web app" />
          </a>
        </div>
      </div>
      <div className="text-hint">
        貼心提醒：若您執行訂單退款，導致消費累積金額未達 1,200
        元門檻，則不符合活動領獎資格
      </div>
    </div>
  );
}

export default Success;
