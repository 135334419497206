import React, { useState } from "react";
import { createContext, useContextSelector } from "use-context-selector";

interface Store {
  email: string;
  setEmail: (email: string) => void;
}

const StoreContext = createContext<Store>({
  email: "",
  setEmail: () => {},
});

export const StoreContextProvider: React.FC = ({ children }) => {
  const [email, setEmail] = useState("");
  return (
    <StoreContext.Provider value={{ email, setEmail }}>
      {children}
    </StoreContext.Provider>
  );
};

export const useEmail = () => useContextSelector(StoreContext, (m) => m.email);
export const useSetEmail = () =>
  useContextSelector(StoreContext, (m) => m.setEmail);
